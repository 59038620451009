import {
  TikTokIcon,
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  YoutubeIcon,
  WhatsAppIcon,
  TelegramIcon
} from '../components/icons';

export const platformData = [
  {
    title: 'X (Twitter)',
    icon: TwitterIcon,
    bannedCountries: ['China', 'North Korea', 'Iran', 'Turkmenistan', 'Myanmar'],
    restrictedCountries: ['Brazil (temporary)']
  },
  {
    title: 'Instagram',
    icon: InstagramIcon,
    bannedCountries: ['China', 'Iran', 'North Korea'],
    restrictedCountries: ['Russia']
  },
  {
    title: 'TikTok',
    icon: TikTokIcon,
    bannedCountries: ['India', 'Afghanistan', 'Nepal', 'Somalia'],
    restrictedCountries: ['United States (Gov)', 'Canada', 'European Union', 'United Kingdom', 'Australia']
  },
  {
    title: 'Facebook',
    icon: FacebookIcon,
    bannedCountries: ['China', 'North Korea', 'Iran', 'Myanmar'],
    restrictedCountries: ['Russia']
  },
  {
    title: 'YouTube',
    icon: YoutubeIcon,
    bannedCountries: ['China', 'Iran', 'Turkmenistan', 'North Korea'],
    restrictedCountries: []
  },
  {
    title: 'WhatsApp',
    icon: WhatsAppIcon,
    bannedCountries: ['China', 'Iran', 'North Korea'],
    restrictedCountries: ['United Arab Emirates']
  },
  {
    title: 'Telegram',
    icon: TelegramIcon,
    bannedCountries: ['China', 'Iran', 'Somalia'],
    restrictedCountries: ['Russia']
  }
];