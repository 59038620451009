import React from 'react';
import { motion } from 'framer-motion';
import { SuccessStoryType } from './types';

interface Props {
  story: SuccessStoryType;
  index: number;
}

export function SuccessStoryCard({ story, index }: Props) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay: index * 0.1 }}
      className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow"
    >
      <div className="relative h-48">
        <img
          src={story.image}
          alt={story.name}
          className="w-full h-full object-contain bg-gray-100"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent">
          <div className="absolute bottom-4 left-4">
            <h4 className="text-white text-xl font-bold">{story.name}</h4>
            <p className="text-purple-200">{story.website}</p>
          </div>
        </div>
      </div>
      <div className="p-6">
        <div className="mb-4">
          <h5 className="font-semibold text-purple-900 mb-2">Offerings</h5>
          <p className="text-gray-600">{story.offerings}</p>
        </div>
        <div className="space-y-2">
          <div className="flex items-center justify-between text-sm">
            <span className="text-gray-600">Monthly Revenue:</span>
            <span className="font-semibold text-green-600">{story.revenue}</span>
          </div>
          <div className="flex items-center justify-between text-sm">
            <span className="text-gray-600">Net Worth:</span>
            <span className="font-semibold text-purple-600">{story.netWorth}</span>
          </div>
        </div>
      </div>
    </motion.div>
  );
}