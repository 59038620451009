import React from 'react';
import { Header } from './components/Header';
import { HeroSection } from './components/HeroSection';
import { CreatorReactionsCarousel } from './components/CreatorReactionsCarousel';
import { PlatformIndependenceSection } from './components/PlatformIndependenceSection';
import { BusinessBenefitsSection } from './components/BusinessBenefits';
import { BenefitsSection } from './components/BenefitsSection';
import { PlatformCard } from './components/PlatformCard';
import { ServicesSection } from './components/ServicesSection';
import { StorySection } from './components/StorySection';
import { WhatsAppButton } from './components/WhatsAppButton';
import { platformData } from './data/platformData';

function App() {
  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      <HeroSection />
      <CreatorReactionsCarousel />
      
      {/* Platforms Grid - Moved up */}
      <section id="bans" className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Current Platform Restrictions</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {platformData.map((platform) => (
              <PlatformCard
                key={platform.title}
                title={platform.title}
                icon={platform.icon}
                bannedCountries={platform.bannedCountries}
                restrictedCountries={platform.restrictedCountries}
              />
            ))}
          </div>
        </div>
      </section>

      <PlatformIndependenceSection />
      <BusinessBenefitsSection />
      <BenefitsSection />
      <StorySection />
      <ServicesSection />

      <WhatsAppButton />

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p>© 2024 Build Your Online Presence. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default App;