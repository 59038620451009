import React from 'react';
import { motion } from 'framer-motion';
import { Sparkles } from 'lucide-react';
import { benefits, successStories } from './data';
import { BenefitCard } from './BenefitCard';
import { SuccessStoryCard } from './SuccessStoryCard';

const glowVariants = {
  initial: {
    textShadow: "0 0 0 rgba(147, 51, 234, 0)"
  },
  animate: {
    textShadow: [
      "0 0 20px rgba(147, 51, 234, 0.5)",
      "0 0 40px rgba(147, 51, 234, 0.3)",
      "0 0 20px rgba(147, 51, 234, 0.5)"
    ],
    transition: {
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut"
    }
  }
};

const sparkleVariants = {
  initial: { scale: 0, opacity: 0 },
  animate: { 
    scale: [0, 1, 0],
    opacity: [0, 1, 0],
    transition: {
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut"
    }
  }
};

export function BusinessBenefitsSection() {
  const whatsappNumber = '+919354785960';
  const whatsappUrl = `https://wa.me/${whatsappNumber}`;

  return (
    <section className="py-20 bg-gradient-to-b from-white to-purple-50">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="relative inline-block"
          >
            <span className="inline-block bg-gradient-to-r from-purple-600 via-purple-400 to-purple-600 text-white px-6 py-3 rounded-full text-sm font-bold mb-4 shadow-lg relative overflow-hidden">
              <span className="relative z-10">INVESTMENT IN YOUR FUTURE</span>
              <motion.div
                className="absolute inset-0 bg-white/20"
                animate={{
                  x: ["100%", "-100%"],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "linear",
                }}
              />
            </span>
          </motion.div>

          <div className="relative">
            <motion.h2
              variants={glowVariants}
              initial="initial"
              animate="animate"
              className="text-6xl font-bold text-purple-900 mb-6 tracking-tight"
            >
              Your Website,{" "}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-indigo-600">
                Your Business
              </span>{" "}
              Growth Engine
            </motion.h2>

            <motion.div
              variants={sparkleVariants}
              initial="initial"
              animate="animate"
              className="absolute -top-4 -right-4"
            >
              <Sparkles className="w-8 h-8 text-purple-400" />
            </motion.div>
          </div>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed"
          >
            Transform your online presence into a powerful business asset that{" "}
            <span className="text-purple-600 font-semibold">generates revenue</span>,{" "}
            <span className="text-purple-600 font-semibold">builds authority</span>, and{" "}
            <span className="text-purple-600 font-semibold">ensures long-term success</span>.
          </motion.p>
        </motion.div>

        {/* Success Stories Section */}
        <div className="mb-20">
          <motion.h3
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-3xl font-bold text-center mb-12 text-purple-900"
          >
            Success Stories: Creators Who Own Their Digital Presence
          </motion.h3>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {successStories.map((story, index) => (
              <SuccessStoryCard key={story.name} story={story} index={index} />
            ))}
          </div>
        </div>

        <div className="grid lg:grid-cols-2 gap-8 mb-16">
          {benefits.map((benefit, index) => (
            <BenefitCard key={benefit.title} benefit={benefit} index={index} />
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="bg-gradient-to-r from-purple-900 to-indigo-900 rounded-2xl p-12 text-white text-center"
        >
          <h3 className="text-3xl font-bold mb-4">
            Ready to Invest in Your Growth?
          </h3>
          <p className="text-xl text-purple-100 mb-8 max-w-2xl mx-auto">
            Join successful creators who've taken control of their digital presence. Your website is more than an expense—it's an investment in your future success.
          </p>
          <motion.a
            href={whatsappUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-white text-purple-900 px-8 py-4 rounded-xl font-semibold text-lg hover:bg-purple-100 transition-colors shadow-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Start Your Growth Journey
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
}