import React from 'react';
import { motion } from 'framer-motion';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const imageVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      ease: "easeOut"
    }
  }
};

const images = [
  {
    src: 'https://res.cloudinary.com/devhqpbee/image/upload/v1733744984/twitterban_fenn4g.jpg',
    alt: 'Twitter Ban',
    caption: 'High-profile account suspensions',
    className: 'col-span-3 row-span-2'
  },
  {
    src: 'https://res.cloudinary.com/devhqpbee/image/upload/v1733743783/instagram_banned_mcploj.webp',
    alt: 'Instagram Restrictions',
    caption: 'Platform access limitations',
    className: 'col-span-2 row-span-1'
  },
  {
    src: 'https://res.cloudinary.com/devhqpbee/image/upload/v1733743782/Facebook-banned_fjmtus.jpg',
    alt: 'Facebook Banned',
    caption: 'Regional restrictions',
    className: 'col-span-2 row-span-2'
  },
  {
    src: 'https://res.cloudinary.com/devhqpbee/image/upload/v1733743673/tiktok-5358337_1280_iyeybc.png',
    alt: 'TikTok Ban',
    caption: 'Global platform bans',
    className: 'col-span-3 row-span-1'
  }
];

export function HeroSection() {
  const whatsappNumber = '+919354785960';
  const whatsappUrl = `https://wa.me/${whatsappNumber}`;

  return (
    <section className="relative min-h-screen flex items-center bg-gradient-to-b from-purple-900 via-indigo-900 to-blue-900 py-20">
      <div className="container mx-auto px-4 relative z-20">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="text-white"
          >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="mb-6"
            >
              <span className="inline-block bg-red-500 text-white px-4 py-2 rounded-full text-sm font-semibold mb-4">
                Breaking News
              </span>
            </motion.div>
            <h1 className="text-5xl md:text-6xl font-bold mb-6 leading-tight">
              The Global Impact of Social Media Restrictions
            </h1>
            <p className="text-xl text-purple-200 mb-8 leading-relaxed">
              From presidential account suspensions to nationwide platform bans, social media restrictions are reshaping the digital landscape. Don't let platform dependencies limit your reach.
            </p>
            <motion.div
              className="space-y-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              <motion.a
                href={whatsappUrl}
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="inline-block bg-white text-purple-900 px-8 py-4 rounded-lg font-semibold text-lg hover:bg-purple-100 transition-colors"
              >
                Secure Your Digital Future
              </motion.a>
              <p className="text-purple-200 text-sm mt-4">
                Join thousands of creators who've already secured their online presence
              </p>
            </motion.div>
          </motion.div>

          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="relative h-[600px] w-full"
          >
            <div className="absolute inset-0 grid grid-cols-5 grid-rows-3 gap-4">
              {images.map((image) => (
                <motion.div
                  key={image.alt}
                  variants={imageVariants}
                  whileHover={{ scale: 1.05, zIndex: 1 }}
                  className={`relative rounded-xl shadow-2xl ${image.className} bg-gray-900 overflow-hidden`}
                >
                  <div className="absolute inset-0">
                    <img
                      src={image.src}
                      alt={image.alt}
                      className="w-full h-full object-contain bg-gray-900"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent pointer-events-none" />
                  </div>
                  <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
                    <h3 className="font-bold text-lg mb-1">{image.alt}</h3>
                    <p className="text-sm text-gray-200">{image.caption}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}