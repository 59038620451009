import { BenefitType, SuccessStoryType } from './types';
import { Users, TrendingUp, Globe2, ShoppingBag } from 'lucide-react';

export const benefits: BenefitType[] = [
  {
    title: "Enhanced Audience Engagement",
    description: "Build deeper connections with your audience through personalized experiences and direct communication channels.",
    icon: Users,
    roi: "Average 40% increase in audience retention"
  },
  {
    title: "Direct Sales Channel",
    description: "Sell products, merchandise, and services directly to your audience without platform fees or restrictions.",
    icon: ShoppingBag,
    roi: "Eliminate 15-30% platform commission fees"
  },
  {
    title: "Platform Independence",
    description: "Own your digital presence without relying on social media algorithms or platform restrictions.",
    icon: Globe2,
    roi: "100% control over your content and audience"
  },
  {
    title: "Expanded Reach",
    description: "Reach beyond social media audiences through SEO and discover new opportunities for growth.",
    icon: TrendingUp,
    roi: "Average 65% increase in organic discovery"
  }
];

export const successStories: SuccessStoryType[] = [
  {
    name: "Bhuvan Bam",
    image: "https://res.cloudinary.com/devhqpbee/image/upload/v1733906478/influcneers%20images/gvfrv82kzeqaniinsr7p.jpg",
    website: "youthiapa.com",
    offerings: "T-shirts, hoodies, and accessories under his merchandising brand, Youthiapa",
    revenue: "₹95 lakhs monthly",
    netWorth: "₹122 crores"
  },
  {
    name: "Ashish Chanchlani",
    image: "https://res.cloudinary.com/devhqpbee/image/upload/v1733906477/influcneers%20images/awxm8o9bppb0djwu5sgu.jpg",
    website: "ogbeauty.in",
    offerings: "Skincare and wellness products, including face washes, perfumes, and supplements",
    revenue: "$20.3K monthly",
    netWorth: "$5 million"
  },
  {
    name: "Virat Kohli",
    image: "https://res.cloudinary.com/devhqpbee/image/upload/v1733906478/influcneers%20images/pf8vzw8xgobdypkeqccw.jpg",
    website: "viratkohli.foundation",
    offerings: "Clothing brand Wrogn, One8 fitness app, brand endorsements",
    revenue: "₹14 crore per post",
    netWorth: "₹1000+ crores"
  },
  {
    name: "Masoom Minawala",
    image: "https://res.cloudinary.com/devhqpbee/image/upload/v1733906478/influcneers%20images/vtb4jdk3kqn9hykrrx8c.jpg",
    website: "masoomminawala.com",
    offerings: "Influencer marketing services, fashion blogging, #SupportIndianDesigners campaign",
    revenue: "$2K-4K monthly",
    netWorth: "Growing"
  },
  {
    name: "Prajakta Koli",
    image: "https://res.cloudinary.com/devhqpbee/image/upload/v1733906478/influcneers%20images/gzxancwpest5qkniy9id.jpg",
    website: "mostlysane.in",
    offerings: "Merchandise including T-shirts, hoodies, and themed apparel",
    revenue: "₹40 lakhs monthly",
    netWorth: "₹4 crores annually"
  },
  {
    name: "Juhi Godambe",
    image: "https://res.cloudinary.com/devhqpbee/image/upload/v1733906478/influcneers%20images/jxljthe8o55tmwykinhc.jpg",
    website: "juhigodambe.com",
    offerings: "Fashion and lifestyle content, founder of Arabella Official",
    revenue: "Growing",
    netWorth: "Rising Star"
  },
  {
    name: "Ranveer Allahbadia",
    image: "https://res.cloudinary.com/devhqpbee/image/upload/v1733906478/influcneers%20images/auybskyndhcgiry3gsya.jpg",
    website: "beerbiceps.com",
    offerings: "Podcasts, fitness and lifestyle content, entrepreneurship advice",
    revenue: "$20K-30K monthly",
    netWorth: "$3-4 million"
  },
  {
    name: "Diipa Büller-Khosla",
    image: "https://res.cloudinary.com/devhqpbee/image/upload/v1733906478/influcneers%20images/jbricmciljb0yjviovdh.jpg",
    website: "indewild.com",
    offerings: "Ayurveda-inspired beauty products, fashion and lifestyle content",
    revenue: "$24K-48K annually",
    netWorth: "$3M seed funding"
  }
];