import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { platformStories } from '../data/platformStories';

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};

const staggerChildren = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const imageVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: {
      duration: 0.5,
      ease: "easeOut"
    }
  }
};

export function StorySection() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <motion.h2 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-3xl font-bold text-center mb-12"
        >
          The Story Behind Social Media Bans
        </motion.h2>
        
        <div className="space-y-24">
          {platformStories.map((story, index) => {
            const [ref, inView] = useInView({
              triggerOnce: true,
              threshold: 0.2
            });

            return (
              <motion.div
                ref={ref}
                key={story.platform}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={fadeInUp}
                className="max-w-6xl mx-auto"
              >
                <div className={`flex flex-col md:flex-row gap-12 items-center ${index % 2 === 1 ? 'md:flex-row-reverse' : ''}`}>
                  <motion.div 
                    className="md:w-1/2"
                    variants={imageVariants}
                  >
                    <motion.div
                      whileHover={{ scale: 1.02 }}
                      transition={{ type: "spring", stiffness: 300 }}
                      className="relative overflow-hidden rounded-xl shadow-xl"
                    >
                      <img
                        src={story.imageUrl}
                        alt={story.platform}
                        className="w-full h-[400px] object-cover"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent" />
                    </motion.div>
                  </motion.div>
                  <motion.div 
                    className="md:w-1/2"
                    variants={staggerChildren}
                  >
                    <motion.h3 
                      variants={fadeInUp}
                      className="text-3xl font-bold mb-6 text-purple-900"
                    >
                      {story.platform}
                    </motion.h3>
                    <div className="space-y-6">
                      <motion.p 
                        variants={fadeInUp}
                        className="text-lg text-gray-700 leading-relaxed"
                      >
                        {story.description}
                      </motion.p>
                      <motion.div 
                        variants={fadeInUp}
                        className="bg-purple-50 p-6 rounded-xl shadow-inner"
                      >
                        <h4 className="font-semibold text-purple-900 mb-4 text-xl">Key Statistics</h4>
                        <ul className="list-disc list-inside text-gray-700 space-y-3">
                          {story.statistics.map((stat, i) => (
                            <motion.li
                              key={i}
                              variants={fadeInUp}
                              custom={i}
                              className="text-base"
                            >
                              {stat}
                            </motion.li>
                          ))}
                        </ul>
                      </motion.div>
                      
                      {story.creatorQuotes && (
                        <motion.div 
                          variants={fadeInUp}
                          className="space-y-4"
                        >
                          <h4 className="font-semibold text-purple-900 text-xl">Creator Reactions</h4>
                          {story.creatorQuotes.map((quote, i) => (
                            <motion.div
                              key={i}
                              variants={fadeInUp}
                              className="bg-white border border-purple-100 p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow"
                            >
                              <blockquote className="text-gray-700 italic mb-4">"{quote.quote}"</blockquote>
                              <div className="flex items-center justify-between text-sm text-gray-600">
                                <span className="font-medium">{quote.author}</span>
                                <span>{quote.followers} followers</span>
                              </div>
                            </motion.div>
                          ))}
                        </motion.div>
                      )}

                      {story.creatorImpact && (
                        <motion.div 
                          variants={fadeInUp}
                          className="bg-blue-50 p-6 rounded-xl shadow-inner"
                        >
                          <h4 className="font-semibold text-blue-900 mb-4 text-xl">Impact on Creators</h4>
                          <p className="text-gray-700 text-base leading-relaxed">{story.creatorImpact}</p>
                        </motion.div>
                      )}
                    </div>
                  </motion.div>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
    </section>
  );
}