import React from 'react';
import { motion } from 'framer-motion';

interface CreatorReaction {
  id: number;
  platform: string;
  author: string;
  handle: string;
  avatar: string;
  content: string;
  date: string;
  engagement: {
    likes: number;
    retweets?: number;
    shares?: number;
  };
}

const creatorReactions: CreatorReaction[] = [
  {
    id: 1,
    platform: 'X',
    author: 'Alex Pearlman',
    handle: '@AlexPearlman',
    avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    content: "Just found out TikTok might be banned. My entire business and community are at risk. This is devastating for creators who've spent years building their presence. We need to diversify our platforms NOW. 😰",
    date: 'March 13, 2024',
    engagement: { likes: 5243, retweets: 1832 }
  },
  {
    id: 2,
    platform: 'X',
    author: 'Katie Wolf',
    handle: '@KatieWolfCreates',
    avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    content: "80% of my clients come through TikTok. This ban would literally destroy my business overnight. We need a backup plan. Anyone else feeling terrified right now? 😥",
    date: 'March 14, 2024',
    engagement: { likes: 3876, retweets: 942 }
  },
  {
    id: 3,
    platform: 'Instagram',
    author: 'Sarah Chen',
    handle: '@SarahChenBeauty',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    content: "Lost 70% of my business overnight when Instagram was restricted in my region. Had to completely pivot my strategy. Building my own website now! 🙏",
    date: 'March 15, 2024',
    engagement: { likes: 8932, shares: 1245 }
  },
  {
    id: 4,
    platform: 'X',
    author: 'Chris Mowrey',
    handle: '@ChrisMowrey',
    avatar: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    content: "Years of building our communities could vanish in an instant. The economic impact on small creators and businesses is massive. We need to own our platforms more than ever. 🚨",
    date: 'March 15, 2024',
    engagement: { likes: 7234, retweets: 2156 }
  },
  {
    id: 5,
    platform: 'TikTok',
    author: 'Lauren Ashley',
    handle: '@LaurenAshleyStyle',
    avatar: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    content: "Having your own website is no longer optional - it's essential for survival in this ever-changing social media landscape. Don't wait until it's too late! 💪",
    date: 'March 16, 2024',
    engagement: { likes: 12543, shares: 3421 }
  },
  {
    id: 6,
    platform: 'X',
    author: 'Mike Thompson',
    handle: '@MikeTeaches',
    avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    content: "My entire educational content series... gone overnight. This is why we need platform independence. Starting fresh with my own website. 📚",
    date: 'March 16, 2024',
    engagement: { likes: 4532, retweets: 892 }
  },
  {
    id: 7,
    platform: 'Instagram',
    author: 'Emma Rodriguez',
    handle: '@EmmaFitness',
    avatar: 'https://images.unsplash.com/photo-1489424731084-a5d8b219a5bb?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    content: "Just lost access to my 500K+ community due to regional restrictions. Time to build something that can't be taken away! 💪",
    date: 'March 17, 2024',
    engagement: { likes: 9876, shares: 2341 }
  },
  {
    id: 8,
    platform: 'TikTok',
    author: 'David Kim',
    handle: '@DavidCooks',
    avatar: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    content: "3 years of cooking content, 2M followers, all at risk. Starting my own cooking website today. Never putting all eggs in one basket again! 🍳",
    date: 'March 17, 2024',
    engagement: { likes: 15234, shares: 4567 }
  }
];

// Double the array to create seamless loop
const doubledReactions = [...creatorReactions, ...creatorReactions];

export function CreatorReactionsCarousel() {
  return (
    <div className="w-full bg-gradient-to-r from-purple-100 to-indigo-100 py-16 overflow-hidden">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-purple-900">Creator Reactions</h2>
        
        <div className="relative">
          <motion.div
            className="flex gap-6"
            animate={{
              x: [0, -100 * creatorReactions.length + '%'],
            }}
            transition={{
              x: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 80, // Doubled the duration from 40 to 80 to make it slower
                ease: "linear",
              },
            }}
          >
            {doubledReactions.map((reaction, index) => (
              <motion.div
                key={`${reaction.id}-${index}`}
                className="flex-shrink-0 w-[400px] bg-white rounded-xl shadow-xl p-6 hover:shadow-2xl transition-shadow"
                whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
              >
                <div className="flex items-start space-x-4">
                  <img
                    src={reaction.avatar}
                    alt={reaction.author}
                    className="w-12 h-12 rounded-full"
                  />
                  <div className="flex-1">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="font-semibold text-gray-900">
                          {reaction.author}
                        </h3>
                        <p className="text-gray-500 text-sm">
                          {reaction.handle}
                        </p>
                      </div>
                      <span className="text-gray-500 text-sm">
                        {reaction.date}
                      </span>
                    </div>
                    <p className="mt-2 text-gray-700">
                      {reaction.content}
                    </p>
                    <div className="mt-4 flex items-center space-x-6 text-gray-500">
                      <span className="flex items-center space-x-2">
                        <span>❤️</span>
                        <span>{reaction.engagement.likes.toLocaleString()}</span>
                      </span>
                      {reaction.engagement.retweets && (
                        <span className="flex items-center space-x-2">
                          <span>🔄</span>
                          <span>{reaction.engagement.retweets.toLocaleString()}</span>
                        </span>
                      )}
                      {reaction.engagement.shares && (
                        <span className="flex items-center space-x-2">
                          <span>↗️</span>
                          <span>{reaction.engagement.shares.toLocaleString()}</span>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
}