import React from 'react';
import { motion } from 'framer-motion';
import { Globe, Lock, AlertTriangle } from 'lucide-react';

const glowAnimation = {
  initial: { boxShadow: '0 0 0 rgba(245, 158, 11, 0)' },
  animate: {
    boxShadow: ['0 0 20px rgba(245, 158, 11, 0.2)', '0 0 40px rgba(245, 158, 11, 0.4)', '0 0 20px rgba(245, 158, 11, 0.2)'],
    transition: {
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut"
    }
  }
};

export function PlatformIndependenceSection() {
  const whatsappNumber = '+919354785960';
  const whatsappUrl = `https://wa.me/${whatsappNumber}`;

  return (
    <section className="py-20 bg-gradient-to-b from-indigo-100 to-white">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto mb-16">
          <motion.div 
            className="bg-white rounded-2xl shadow-xl overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <div className="grid md:grid-cols-2 gap-8">
              <div className="p-8 space-y-6">
                <motion.h2 
                  className="text-4xl font-bold mb-6 text-purple-900"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                >
                  Why You Need Your Own Website
                </motion.h2>
                
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.2 }}
                  className="bg-red-50 p-6 rounded-xl border border-red-200"
                >
                  <p className="text-lg text-red-800">
                    <span className="font-bold">Warning:</span> If Donald Trump, with millions of followers, can be banned from social media platforms, no one is immune. Your entire digital empire could vanish overnight.
                  </p>
                </motion.div>

                <motion.p
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.3 }}
                  className="text-lg text-gray-700"
                >
                  One mistake, one policy change, or one government decision can erase years of hard work. Don't let your online presence depend on platforms you don't control.
                </motion.p>

                <motion.div
                  variants={glowAnimation}
                  initial="initial"
                  animate="animate"
                  className="bg-gradient-to-r from-yellow-400 to-orange-500 p-6 rounded-xl text-white"
                >
                  <h3 className="text-2xl font-bold mb-3">🚨 Domain Names Are Selling Fast!</h3>
                  <p className="text-white/90">
                    Just like how difficult it is to get your preferred username on social media platforms, premium domain names are being claimed every day. Secure your personal domain name now before someone else takes it.
                  </p>
                </motion.div>
              </div>

              <div className="relative h-full flex items-center bg-gray-900">
                <div className="relative w-full pt-[75%]">
                  <img
                    src="https://res.cloudinary.com/devhqpbee/image/upload/v1733744984/twitterban_fenn4g.jpg"
                    alt="Twitter Ban Impact"
                    className="absolute inset-0 w-full h-full object-contain"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent">
                    <div className="absolute bottom-8 left-8 right-8 text-white">
                      <h3 className="text-2xl font-bold mb-2">The Twitter Ban Effect</h3>
                      <p className="text-white/90">A stark reminder that no social media presence is truly permanent</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            whileHover={{ scale: 1.05 }}
            className="bg-white p-8 rounded-xl shadow-lg"
          >
            <Globe className="w-12 h-12 text-purple-600 mb-4" />
            <h3 className="text-xl font-semibold mb-3">Platform Independence</h3>
            <p className="text-gray-600">Own your digital presence without relying on social media platforms that can ban or restrict you at any time.</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            whileHover={{ scale: 1.05 }}
            transition={{ delay: 0.1 }}
            className="bg-white p-8 rounded-xl shadow-lg"
          >
            <Lock className="w-12 h-12 text-purple-600 mb-4" />
            <h3 className="text-xl font-semibold mb-3">Secure Your Brand</h3>
            <p className="text-gray-600">Lock in your perfect domain name before it's taken. Your personal brand deserves its own home on the internet.</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            whileHover={{ scale: 1.05 }}
            transition={{ delay: 0.2 }}
            className="bg-white p-8 rounded-xl shadow-lg"
          >
            <AlertTriangle className="w-12 h-12 text-purple-600 mb-4" />
            <h3 className="text-xl font-semibold mb-3">Don't Wait Until It's Too Late</h3>
            <p className="text-gray-600">Platform bans can happen without warning. Build your backup plan before you need it.</p>
          </motion.div>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center"
        >
          <motion.a
            href={whatsappUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-purple-600 text-white px-12 py-6 rounded-xl font-bold text-xl hover:bg-purple-700 transition-colors shadow-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Secure Your Domain Today
          </motion.a>
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="mt-4 text-gray-600"
          >
            Don't let someone else claim your perfect domain name
          </motion.p>
        </motion.div>
      </div>
    </section>
  );
}