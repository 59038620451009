export const platformStories = [
  {
    platform: 'Instagram',
    imageUrl: 'https://res.cloudinary.com/devhqpbee/image/upload/v1733743783/instagram_banned_mcploj.webp',
    description: "Instagram has faced restrictions in several countries, primarily due to content moderation concerns and data privacy issues. The platform's evolution from a photo-sharing app to a multi-format content platform has brought both opportunities and challenges.",
    statistics: [
      'Banned in China, Iran, and North Korea',
      'Partial restrictions in Russia',
      'Over 2 billion monthly active users globally',
      'Significant impact on influencer marketing industry'
    ],
    creatorImpact: "Instagram creators have been particularly affected by regional restrictions, leading to the development of alternative content strategies and increased focus on building platform-independent audiences through personal websites and newsletters.",
    creatorQuotes: [
      {
        author: "Lauren Ashley",
        quote: "Having your own website is no longer optional - it's essential for survival in this ever-changing social media landscape.",
        followers: "300K+"
      }
    ]
  },
  {
    platform: 'TikTok',
    imageUrl: 'https://res.cloudinary.com/devhqpbee/image/upload/v1733743673/tiktok-5358337_1280_iyeybc.png',
    description: "TikTok's journey has been marked by unprecedented growth and equally significant challenges. The platform's ability to capture young audiences globally has led to increased scrutiny from governments worldwide, particularly regarding data privacy and national security concerns.",
    statistics: [
      'Over 1 billion monthly active users globally before bans',
      'Banned in India, affecting 200 million users',
      'Restricted on government devices in over 15 countries',
      'Over 170 million users in the United States face potential ban'
    ],
    creatorImpact: "The platform's restrictions have dramatically affected creators worldwide. Katie Wolf, a book editor with a significant following, reported that 80% of her clients came through TikTok. Creator Sandra Macedo (153k followers) faced potential losses of $13,850 in contracted collaborations.",
    creatorQuotes: [
      {
        author: "Katie Wolf",
        quote: "I'm literally shaking — I'm furious and anxious, because TikTok has transformed my life.",
        followers: "500K+"
      },
      {
        author: "Alex Pearlman",
        quote: "It's about to get chaotic. We have no idea what will remain monetized or how the TikTok Shops will function.",
        followers: "2.5M"
      }
    ]
  },
  {
    platform: 'X (Twitter)',
    imageUrl: 'https://res.cloudinary.com/devhqpbee/image/upload/v1733743903/twitter_afrddx.jpg',
    description: "The platform formerly known as Twitter has faced various restrictions globally, often during periods of political unrest or due to content moderation disputes. Creators have had to navigate sudden policy changes and regional restrictions.",
    statistics: [
      'Permanently blocked in China since 2009',
      'Periodic restrictions in multiple countries during elections',
      'Temporary bans affecting millions of users worldwide',
      'Significant impact on global information flow during critical events'
    ],
    creatorImpact: "Creators have implemented various adaptation strategies, including cross-posting content to Instagram Reels and YouTube Shorts. Many actively direct their followers to alternative platforms, with some exploring newer options like Threads and Bluesky.",
    creatorQuotes: [
      {
        author: "Chris Mowrey",
        quote: "For the first time, I'm seeing years of what worked potentially vanish. The economic impact on small businesses and creators hasn't been discussed enough.",
        followers: "470K"
      }
    ]
  },
  {
    platform: 'Facebook',
    imageUrl: 'https://res.cloudinary.com/devhqpbee/image/upload/v1733743782/Facebook-banned_fjmtus.jpg',
    description: "Facebook's global presence has faced significant challenges in countries prioritizing digital sovereignty. The platform's struggles with content moderation and data privacy have led to complete bans in several nations.",
    statistics: [
      'Blocked in China since 2009',
      'Restricted access in Russia since 2022',
      'Temporary shutdowns during political events in multiple countries',
      'Over 2.9 billion monthly active users globally'
    ],
    creatorImpact: "Content creators have been forced to adapt their strategies, with many diversifying their presence across multiple platforms. The restrictions have particularly impacted small businesses and content creators who relied on Facebook's extensive reach for their livelihood.",
    creatorQuotes: [
      {
        author: "Sarah Chen",
        quote: "Lost 70% of my business overnight when Facebook was restricted in my region. Had to completely pivot my strategy.",
        followers: "250K+"
      }
    ]
  }
];