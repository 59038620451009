import React from 'react';
import { Platform } from '../types/platform';

type PlatformCardProps = Platform;

export function PlatformCard({ title, icon: IconComponent, bannedCountries, restrictedCountries }: PlatformCardProps) {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-all">
      <div className="flex items-center space-x-3 mb-4">
        <IconComponent className="h-8 w-8 text-purple-600" />
        <h3 className="text-xl font-semibold">{title}</h3>
      </div>
      <div className="space-y-4">
        <div>
          <h4 className="font-medium text-gray-700 mb-2">Fully Banned:</h4>
          <ul className="list-disc list-inside text-gray-600">
            {bannedCountries.map((country) => (
              <li key={country} className="hover:text-purple-600 transition-colors">
                {country}
              </li>
            ))}
          </ul>
        </div>
        {restrictedCountries.length > 0 && (
          <div>
            <h4 className="font-medium text-gray-700 mb-2">Restricted/Government Devices:</h4>
            <ul className="list-disc list-inside text-gray-600">
              {restrictedCountries.map((country) => (
                <li key={country} className="hover:text-purple-600 transition-colors">
                  {country}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}