import React from 'react';
import { motion } from 'framer-motion';
import { ArrowUpRight } from 'lucide-react';
import { BenefitType } from './types';

interface Props {
  benefit: BenefitType;
  index: number;
}

export function BenefitCard({ benefit, index }: Props) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay: index * 0.1 }}
      className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-all group"
    >
      <div className="flex items-start space-x-4">
        <div className="bg-purple-100 w-16 h-16 rounded-xl flex items-center justify-center flex-shrink-0 group-hover:bg-purple-600 group-hover:text-white transition-colors">
          <benefit.icon className="w-8 h-8 text-purple-600 group-hover:text-white" />
        </div>
        <div>
          <h3 className="text-2xl font-bold mb-2 text-purple-900 group-hover:text-purple-600 transition-colors">
            {benefit.title}
          </h3>
          <p className="text-gray-600 mb-4">
            {benefit.description}
          </p>
          <div className="bg-green-50 p-3 rounded-lg">
            <p className="text-green-800 font-semibold flex items-center">
              <ArrowUpRight className="w-5 h-5 mr-2" />
              {benefit.roi}
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
}